@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-gray-50 min-h-screen font-sans text-gray-800 leading-normal tracking-normal;
}

h1 {
    @apply text-5xl font-bold;
}

h2 {
    @apply text-4xl font-bold;
}

h3 {
    @apply text-3xl font-bold;
}

h4 {
    @apply text-2xl font-bold;
}

h5 {
    @apply text-xl font-bold;
}

h6 {
    @apply text-lg font-bold;
}
